module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Uschtrin Verlag - Website","short_name":"Uschtrin","description":"Die Website des Uschtrin Verlags, des Fachverlags für Autorinnen und Autoren.","start_url":"/","background_color":"#fff","theme_color":"#41318E","display":"minimal-ui","lang":"de","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1ac860d5b32d96713a5ec0ba57180dda"},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
