import { tailwind } from "@theme-ui/presets"

export default {
  ...tailwind,
  sizes: {
    ...tailwind.sizes,
    container: 1024,
  },
  colors: {
    ...tailwind.colors,
    useColorSchemeMediaQuery: true,
    primary: "#41318E",
    muted: "#C9C2EA",
    darken: "#30246A",
    modes: {
      dark: {
        primary: "hsl(260,100%,80% )",
        secondary: "hsl(290,100%,80% )",
        text: "hsl(210,50%,96% )",
        background: "hsl(230,25%,18% )",
      },
    },
  },
  text: {
    default: {
      pt: 2,
    },
    siteTitle: {
      fontWeight: 900,
      mb: 4,
    },
    heading: {
      pb: 3,
    },
    subheading: {
      fontSize: 2,
      fontWeight: "bold",
      fontStyle: "italic",
      pt: 4,
    },
  },
  grids: {
    default: {
      pt: 2,
    },
  },
  styles: {
    ...tailwind.styles,
    hr: {
      m: 100,
    },
    li: {
      mb: 1,
    },
    a: {
      color: "primary",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  alerts: {
    form: {
      fontSize: 0,
      bg: "white",
      color: "primary",
      fontStyle: "unset",
      p: 0,
      pb: 2,
      fontWeight: 400,
    },
  },
  links: {
    default: {
      color: "primary",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  buttons: {
    ...tailwind.buttons,
    primary: {
      cursor: "pointer",
    },
  },
}
