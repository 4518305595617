// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bestellformular-js": () => import("./../../../src/pages/bestellformular.js" /* webpackChunkName: "component---src-pages-bestellformular-js" */),
  "component---src-pages-buecher-js": () => import("./../../../src/pages/buecher.js" /* webpackChunkName: "component---src-pages-buecher-js" */),
  "component---src-pages-buecher-uschtrin-handbuch-fuer-autorinnen-und-autoren-js": () => import("./../../../src/pages/buecher/uschtrin-handbuch-fuer-autorinnen-und-autoren.js" /* webpackChunkName: "component---src-pages-buecher-uschtrin-handbuch-fuer-autorinnen-und-autoren-js" */),
  "component---src-pages-buecher-uschtrin-schreiben-mit-chatgpt-js": () => import("./../../../src/pages/buecher/uschtrin-schreiben-mit-chatgpt.js" /* webpackChunkName: "component---src-pages-buecher-uschtrin-schreiben-mit-chatgpt-js" */),
  "component---src-pages-buecher-weber-lyrik-schreiben-und-veroeffentlichen-js": () => import("./../../../src/pages/buecher/weber-lyrik-schreiben-und-veroeffentlichen.js" /* webpackChunkName: "component---src-pages-buecher-weber-lyrik-schreiben-und-veroeffentlichen-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mediadaten-js": () => import("./../../../src/pages/mediadaten.js" /* webpackChunkName: "component---src-pages-mediadaten-js" */),
  "component---src-pages-presse-js": () => import("./../../../src/pages/presse.js" /* webpackChunkName: "component---src-pages-presse-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-widerruf-js": () => import("./../../../src/pages/widerruf.js" /* webpackChunkName: "component---src-pages-widerruf-js" */),
  "component---src-pages-zeitschriften-js": () => import("./../../../src/pages/zeitschriften.js" /* webpackChunkName: "component---src-pages-zeitschriften-js" */)
}

